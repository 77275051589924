import React from 'react'
import AcUnitRoundedIcon from '@mui/icons-material/AcUnitRounded'
import { useTranslation } from 'react-i18next'
import './Navbar.css'

function Navbar() {
    const { t, i18n } = useTranslation()

    function changeLanguage(language) {
        i18n.changeLanguage(language)
    }

    return (
        <nav className="navbar">
            <div className="navbar-left">
                <AcUnitRoundedIcon />
                <span>geloodev</span>
            </div>

            <div className="navbar-right">
                <li className="nav-li">
                    <a href="#my-works">{t('navbar.my-works')}</a>
                </li>
                <li className="nav-li">
                    <a href="#about-me">{t('navbar.about-me')}</a>
                </li>
                <li className="nav-li">
                    <a href="#my-links">{t('navbar.my-links')}</a>
                </li>
                <select className="language-selection"
                    onChange={(e) => changeLanguage(e.target.value)}
                    defaultValue={i18n.language}>
                    <option value="en">🇺🇸</option>
                    <option value="br">🇧🇷</option>
                </select>
            </div>

            <input type="checkbox" id="sidebar-active" />
            <label className="open-menu-btn" for="sidebar-active">
                <i class='bx bx-menu'></i>
            </label>

            <label id="overlay" for="sidebar-active"></label>
            <ul className="mobile-menu">
                <label className='close-menu-btn' for="sidebar-active">
                    <i class="bx bx-x"></i>
                </label>
                <div className='mobile-menu-items'>
                    <li className="nav-li">
                        <a href="#my-works">{t('navbar.my-works')}</a>
                    </li>
                    <li className="nav-li">
                        <a href="#about-me">{t('navbar.about-me')}</a>
                    </li>
                    <li className="nav-li">
                        <a href="#my-links">{t('navbar.my-links')}</a>
                    </li>
                </div>
            </ul>
        </nav>
    )
}

export default Navbar;

