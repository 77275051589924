import "/node_modules/flag-icons/css/flag-icons.min.css"
import './i18n'
import './App.css'
import Navbar from './components/Navbar/Navbar'
import Hero from './components/Hero/Hero'
import MyWorks from './components/MyWorks/MyWorks'
import AboutMe from './components/AboutMe/AboutMe'
import MyLinks from './components/MyLinks/MyLinks'
import Footer from './components/Footer/Footer'
import SysProvider from "./context/SysProvider"

function App() {

    return (
        <div className="App">
            <div className="container">
                <SysProvider>
                    <Navbar />
                    <Hero />
                    <MyWorks />
                    <AboutMe />
                    <MyLinks />
                    <Footer />
                </SysProvider>
            </div>
        </div>
    );
}

export default App;
