import React from 'react'
import LaunchIcon from '@mui/icons-material/Launch'
import { useTranslation } from 'react-i18next'
import './Project.css'

function Project({ imgUrl, title, description, link, tags }) {
    const { t } = useTranslation()

    return (
        <div className="project">
            <div className="project-img">
                <img src={imgUrl} alt="" />
            </div>

            <div className="project-wrapper">
                <div className="project-text">
                    <h4>{title}</h4>
                    <p>{description}</p>
                    <div className="tags">
                        {tags.map((tag) => (
                            <span className='tag'>{tag}</span>
                        ))}
                    </div>
                </div>

                <a href={link} className="project-btn">
                    <span>{t('projects.btn')}</span>
                    <LaunchIcon />
                </a>
            </div>
        </div>
    )
}

export default Project;
