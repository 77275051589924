import React, { useContext } from 'react'
import './MyLinks.css'
import { AnimationContext } from '../../context/AnimationProvider';
import { useTranslation } from 'react-i18next'

function MyLinks() {
    const { t } = useTranslation()
    const { addToRefs } = useContext(AnimationContext)

    return (
        <section id="my-links" className="my-links-section hidden" ref={addToRefs}>
            <h2 className="section-title"><span>#</span>{t('my-links.title')}</h2>
            <div className="my-links-content">
                <span className="hidden" ref={addToRefs}>
                    {t('my-links.text')}
                </span>
                <div className="links hidden" ref={addToRefs}>
                    <a href="mailto:geloodev@gmail.com" className="link"
                        target="_blank" rel="noreferrer">
                        <i class="link-icon bx bxl-gmail"></i>
                        <span>geloodev@gmail.com</span>
                    </a>
                    {/*
                    <a href="google.com" className="link">
                        <i class="link-icon bx bxl-whatsapp"></i>
                        <span>+55 19 98231-3880</span>
                    </a>
                    */}
                    <a href="https://www.linkedin.com/in/angelo-holandini/"
                        className="link" target="_blank" rel="noreferrer">
                        <i class="link-icon bx bxl-linkedin-square"></i>
                        <span>/angelo-holandini</span>
                    </a>
                    <a href="https://github.com/geloodev" className="link"
                        target="_blank" rel="noreferrer">
                        <i class="link-icon bx bxl-github"></i>
                        <span>/geloodev</span>
                    </a>
                    <a href="https://drive.google.com/file/d/1s6n8cYX1tIi6ZHUbZbR9J7cg7glW7Xaq/view?usp=sharing"
                        className="link" target="_blank" rel="noreferrer">
                        <i class="link-icon bx bx-file"></i>
                        <span>{t('my-links.my-resume')}</span>
                    </a>
                </div>
            </div>
        </section>
    )
}

export default MyLinks;
