import React from 'react'
import { useTranslation } from 'react-i18next'
import './Hero.css'

function Hero() {
    const { t } = useTranslation()

    return (
        <div className="hero">
            <div className="hero-title">
                {t('hero.title.part-1')}
                <br />
                <span id="my-name">
                    {t('hero.title.part-2')}
                </span>,
                <br />
                <span id="fullstack">
                    {t('hero.title.part-3')}
                </span>
            </div>
            <div className="my-profile">
                <img src="my-picture.jpg" alt="" />
                <div className="my-profile-text">
                    Ângelo Holandini <br />
                    <span>@geloodev</span>
                </div>
            </div>
        </div>
    )
}

export default Hero;
