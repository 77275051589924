import React, { useContext } from 'react'
import './AboutMe.css'
import { AnimationContext } from '../../context/AnimationProvider';
import { useTranslation } from 'react-i18next'

function AboutMe() {

    const { t } = useTranslation()
    const { addToRefs } = useContext(AnimationContext)

    return (
        <section id="about-me" className="about-me-section hidden" ref={addToRefs}>
            <h2 className="section-title"><span>#</span>{t('about-me.title')}</h2>

            <div className="about-me-content hidden" ref={addToRefs}>
                <div className="about-me-text">
                    {t('about-me.text.part-1')}
                    <br /><br />
                    {t('about-me.text.part-2')}
                    <br /><br />
                    {t('about-me.text.part-3')}
                    <br /><br />
                    {t('about-me.text.part-4')}
                </div>

                <div className="skills">
                    <div className="skills-group">
                        <div className="skills-group-title">
                            skills
                        </div>
                        <div className="skills-group-text">
                            <div className="skill-item">
                                <i className="skill-logo bx bxl-java"></i>
                                <span>java</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo bx bxl-spring-boot"></i>
                                <span>spring boot</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo devicon-c-plain"></i>
                                <span>c</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo devicon-lua-plain"></i>
                                <span>lua</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo devicon-react-plain"></i>
                                <span>react</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo devicon-vuejs-plain"></i>
                                <span>vue.js</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo devicon-javascript-plain">
                                </i>
                                <span>javascript</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo devicon-html5-plain"></i>
                                <span>html</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo devicon-css3-plain"></i>
                                <span>css</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo devicon-postgresql-plain">
                                </i>
                                <span>postgresql</span>
                            </div>
                        </div>
                    </div>

                    <div className="skills-group">
                        <div className="skills-group-title">
                            {t('about-me.tools')}
                        </div>
                        <div className="skills-group-text">
                            <div className="skill-item">
                                <i className="skill-logo bx bxl-git"></i>
                                <span>git</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo bx bxl-github"></i>
                                <span>github</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo devicon-docker-plain"></i>
                                <span>docker</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo devicon-figma-plain"></i>
                                <span>figma</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo devicon-linux-plain"></i>
                                <span>linux</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo devicon-archlinux-plain"></i>
                                <span>arch</span>
                            </div>
                            <div className="skill-item">
                                <i className="skill-logo devicon-neovim-plain"></i>
                                <span>neovim</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutMe;
