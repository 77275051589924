import React, { useEffect, useRef } from 'react'

export const AnimationContext = React.createContext()

function AnimationProvider(props) {

    const refs = useRef([])

    const addToRefs = (el) => {
        if (el && !refs.current.includes(el)) {
            refs.current.push(el)
        }
    }

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('show')
                }
                /*
                else {
                    entry.target.classList.remove('show')
                }
                */
            })
        }, { threshold: 0.1 })

        refs.current.forEach((ref) => {
            observer.observe(ref)
        })

        return () => {
            observer.disconnect()
        }
    }, [])


    return (
        <AnimationContext.Provider
            value={{ addToRefs }}>
            {props.children}
        </AnimationContext.Provider>
    )
}

export default AnimationProvider;
