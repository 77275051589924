import React from 'react'
import { useTranslation } from 'react-i18next'

export const ProjectsContext = React.createContext()

function ProjectsProvider(props) {

    const { t } = useTranslation()
    const projects = [
        {
            imgUrl: "/projects-images/my-portfolio.png",
            title: t('projects.project-1.title'),
            description: t('projects.project-1.description'),
            link: "https://www.geloodev.com",
            tags: [
                "Javascript",
                "React",
                "HTML",
                "CSS"
            ]
        }
    ]

    return (
        <ProjectsContext.Provider value={{ projects }}>
            {props.children}
        </ProjectsContext.Provider>
    )
}

export default ProjectsProvider;
