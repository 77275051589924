import AnimationProvider from "./AnimationProvider"
import ProjectsProvider from "./ProjectsProvider"

function SysProvider({ children }) {
    return (
        <AnimationProvider>
            <ProjectsProvider>
                {children}
            </ProjectsProvider>
        </AnimationProvider>
    )
}

export default SysProvider;
